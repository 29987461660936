import React, { useContext } from 'react';
import { ReducerContext } from './react-application';
import HeaderStore from './store/header';

export function Header({ size, title, subtitle }) {
    return <div className="header centered vertical">
        <a href="/" className="logo">
            <img src="/logo.png" className="logoImg"/>
            <h1 className="logoHeading">
            <div className="logoCaption">{subtitle}</div>
            <div className="logoName">{title}</div>
            </h1>
        </a>
        <img src="/illustration.png" className="watermark"/>
    </div>;
}

export default function HeaderContainer() {
    var store = new HeaderStore(useContext(ReducerContext));

    return <Header size={store.state.size} title={store.state.title} subtitle={store.state.subtitle}/>
}