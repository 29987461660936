import React, { useContext } from 'react';
import { ReducerContext } from './react-application';
import SignupStore from './store/signup';

export default function SignupForm() {
    var store = new SignupStore(useContext(ReducerContext));

    return <div className="container">
        <p className="heading">Be the first to start.</p>
        <form onSubmit={store.handleSignup}>
            <input type="text" name="fullname" placeholder="What's your fullname?"/>
            <input type="text" name="email" placeholder="What's your email?"/>
            <button type="submit" className="button" style={{width: '100%'}}>
                Start Your First Chapter
            </button>
        </form>
    </div>;
}
