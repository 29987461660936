import { ApplicationStore } from '../react-application';
import HeaderStore from './header';
import UserStore from './user';

export default class AppStore {

    constructor(props, useReducer) {
        [this.state, this.dispatch] = useReducer(AppStore.reduce, props, AppStore.initialState);
    }

    // The initialState passed into these stores will 
    // only be used on first instantiation per mount of app.
    // These props should be used as initialisation props
    // by the sub stores (i.e hydration from local storage etc),
    // they will affect what the initial state is in this.initialState
    static initialState(props) {
        return {
            header: HeaderStore.initialState(props),
            user: UserStore.initialState(props)
        };
    }

    static reduce(state, action) {
        state.header = HeaderStore.reduce(state.header, action);
        state.user = UserStore.reduce(state.user, action);

        return super.reduce(state, action);
    }
}

