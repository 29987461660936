export default class SignupStore {
    constructor([state, dispatch]) {
        this.state = state;
        this.dispatch = dispatch;
    }

    handleSignup = ev => {
        this.dispatchSignup({ fullname: ev.fullname.value });
    }

    dispatchSignup = (data) => {
        this.dispatch({ type: "SIGNUP", data });
    }
}