import './index.css';
import React, { useReducer } from 'react';
import { ReducerContext } from './react-application';
import AppRouter from './router';
import Header from './header';
import Store from './store/app';

export default function App(props) {
    var store = new Store(props, useReducer);

    return <ReducerContext.Provider value={[store.state, store.dispatch, store]}>
        <div className="App">
            <Header/>
            <div className="AppBody">
                <div className="AppBodyInner">
                    <AppRouter/>
                </div>
            </div>
        </div>
    </ReducerContext.Provider>;
}