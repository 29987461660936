import React from 'react';
import Pitch from './pitch';
import Signup from './signup';

export default function Home() {
    return <div className="home">
        <Pitch/>
        <Signup/>
    </div>
}


