import { combineReducer } from '../react-application';

export const CHANGE_ACTION = 'HEADER_CHANGE';

export default class HeaderStore /* extends ComponentStore */ {

    // Because this is a module store,
    // not an application store,
    // it accepts the application dispatcher,
    // instead of creating it's own dispatcher.
    // and it accepts the application state,
    // storing it's own substate.
    constructor([state, dispatch]) {
        this.state = state.header;
        this.dispatch = dispatch;
    }

    static installProps() {

    }

    static initialState(props) {
        return {
            size: "large",
            title: "Incremental Company",
            subtitle: "Codifying Startup Execution"
        }
    }

    // This function initialising the state
    // within the application store,
    // in comparison to HeaderStore.initialState, 
    // which initialises this stores sub state.
    // `applicationState` is the application's state,
    // `initialState` is the result of calling
    // HeaderStore.initialState()
    static installState(applicationState, initialState) {
        return {
            ...state,
            header: initialState
        };
    }

    dispatchChange({ size, title, subtitle }) {
        this.dispatch({ type: CHANGE_ACTION, size, title, subtitle });
    }

    static [CHANGE_ACTION] = (state, { size, title, subtitle }) => {
        return {
            size,
            title,
            subtitle
        };
    }

    static reduce() {}
}

/*
combineReducer(
    HeaderStore.reduce,
    HeaderStore.installProps,
    HeaderStore.initialState,
    HeaderStore.installState
);
*/