import React from 'react';

export default function HomePitch() {
    return <div className="pitch">
            <PitchContainer label={"Mission"}>
                <p>
                <em>Our mission is to give you
                a platform which takes the guess work out of realising your passions</em>

                &nbsp;and turning them into your ideal career, 
                whether that means <em>launching your own startup&nbsp;</em>
                or <em>excelling with a team</em> that values your passions. 
                </p>
            </PitchContainer>
            <PitchContainer label={"Why"}>
                <p>
                    <em>We believe in a future where every person is passionate about their work.</em>
                    &nbsp;We know that many people struggle to find their true passions, 
                    and for those that do, 
                    turning their dreams into reality involves a mysterious path to success,
                    is fraught with risks that lead to 98% of attempts at launching new businesses failing,
                    and those that do, with a work force
                    that is often unsatisfied within their company.
                </p>
            </PitchContainer>
            <PitchContainer label={"Vision"}>
                <p>
                    <em>
                        We believe we can demystify 
                        what the most successful company founders call "startup execution";
                        and in doing so, help you live your passions.
                    </em>
                </p>
                {/*<p>We're creating a software platform filled with tools that guide you through exactly what your next step is, 
                along side our own guide book The Incremental Code, which distills our philosophy of the industry's best advice into an actionable plan.
                </p>*/}
                <p>
                Imagine simply listing your passions, and launching your own business at the click of a button.
                </p>
                <p>
                Imagine a world where companies appreciate all your passions.
                Imagine if companies designed their job descriptions,
                based on their potential employees, instead of potential employees having to mold to pre-defined roles.
                
                Imagine having that app idea,
                and designing your own custom software application with just a few words.
                </p>
            </PitchContainer>
            <PitchContainer label={"A unique approach"}>
                <p>We're creating a software platform to power an industry of companies, 
                    with a special focus on forfulling the passions of the individuals in those companies.</p>
            </PitchContainer>
            <PitchContainer label={"Incremental Tim"}>
                <p>
                    <em>Incremental Tim plays the role of founder, product designer
                    and software engineer for Incremental Company.
                    </em>
                </p>
                <p>
                    <em>
                        Tim is the author of The Incremental Code,
                        which will answer all the hard questions needed to guide you
                        through living your passions, creating your personal brand
                        and committing to your dream company.
                    </em>
                </p>
                <p>
                    For over a decade Tim has worked with software startups across the world,
                    and more recently as an Associate with Tech Stars investment company,
                    known for accelerating startups like Uber and AirBnb.
                </p>
                <p>
                    Tim has a Bsc in Computer Science and Psychology, 
                    and is passionate about seeing people live their true path,
                    and in love with every minute of their life.
                </p>
                {/*<p>
                    Tim has a BSc in two subjects, Computer Science and Psychology, 
                    originally blended with the intention to create well designed computer 
                    interfaces for human minds,
                    after being hell bent on discovering his own passions
                    in the work place, Tim discovered his interest lay in entrepreneurship,
                    and that this combination of subjects, 
                    along with a mind that tends towards thinking about how things work,
                    is even better suited to demystify the secrets behind pursuing our minds passions
                    and running businesses in today's world of technological innovation.
                    
                </p>*/}
            </PitchContainer>
            <PitchContainer label={"Our Audacious Promise"}>
                <p>
                We understand that there's a massive amount of - sometimes conflicting -
                information to guide you online.
                </p>
                <p>
                    <em>
                    Our aim is to consolidate that into one holistic flow and understanding
                    in The Incremental Code, and Incremental Company software platform.
                    I'd like to make you two promises.
                    </em>
                </p>
                <p>
                    Firstly, that we'll give you everything we have to offer for free,
                    until you feel you're living your true path, without any hinderance.
                </p>
                <p>
                    Secondly, I'd like to offer you the most innovative business model I can,
                    that we'll only charge you along the way if you choose not to use our platform to the fullest,
                    that is, if you choose not to express yourself and make your progress public in our community.
                </p>
            </PitchContainer>
        </div>
}

function PitchContainer({ label, children }) {
    return  <div className="pitch-container">
        <label>{label}</label>
        {children}
    </div>;
}