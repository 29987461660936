export default class UserStore {
    static initialState() {
        return {
            id: null,
            token: null,
            fullname: "",
            email: ""
        };
    }
    handleSignup = (ev) => {
        ev.preventDefault();
    }
}