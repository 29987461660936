import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
//import firebase from 'firebase';

// Initialize Firebase
var config = {
    apiKey: "AIzaSyBM8r3CrHD5fTDbI8SDbGBMt3LozRlWmMw",
    authDomain: "incrementalcompany-94863.firebaseapp.com",
    databaseURL: "https://incrementalcompany-94863.firebaseio.com",
    projectId: "incrementalcompany-94863",
    storageBucket: "incrementalcompany-94863.appspot.com",
    messagingSenderId: "999777109215"
};

// firebase.initializeApp(config);

ReactDOM.render(<App/>, document.querySelector("#app-container"));