import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Home from './home';

export default function AppRouter() {
    return (
        <Router>
            <Route exact path="" component={Home}/>
            {/*<Route exact path="/passion" component={PassionPage}/>*/}
        </Router>
    );
}